import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { rem } from "polished"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"

import MetaWp from "../components/meta-wp"
import Components from "../components/components"

const Background = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  @media ${props => props.theme.mediumDown} {
    display: none;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${rem(500)};
    background-image: linear-gradient(
      180deg,
      rgba(10, 10, 10, 0.8) 0%,
      rgba(10, 10, 10, 0) 100%
    );
    z-index: 10;
  }

  > * {
    width: 100%;
    height: 100%;
  }
`

const DefaultTemplate = ({ data }) => {
  const { wp, metaWp, allPages, allPosts, allCharacters } = data
  const {
    children,
    acf: { backgroundImage },
  } = wp

  return (
    <React.Fragment>
      <MetaWp wp={metaWp} />

      {backgroundImage && (
        <Background>
          {backgroundImage.localFile && (
            <Img
              fluid={backgroundImage.localFile.childImageSharp.fluid}
              role="presentation"
            />
          )}
        </Background>
      )}

      <h1 className="is-vhidden">{wp.title}</h1>

      <Components
        components={children}
        allPages={allPages}
        allPosts={allPosts}
        allCharacters={allCharacters}
      />
    </React.Fragment>
  )
}

DefaultTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DefaultTemplate

export const pageQuery = graphql`
  query($id: String!) {
    ...MetaWpPage

    ...AllPagesFragment
    ...AllPostsFragment
    ...AllCharactersFragment

    wp: wordpressPage(id: { eq: $id }) {
      ...PageDefaultFragment
    }
  }
`
