import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import Img from "gatsby-image/withIEPolyfill"

import SectionHeader from "./section-header"
import PostBox from "./post-box"
import CallToActions from "./call-to-actions"

const Container = styled.section`
  padding-bottom: ${rem(110)};
  position: relative;

  @media ${props => props.theme.mediumDown} {
    padding-bottom: ${rem(70)};
  }

  &.has-bg {
    background: radial-gradient(rgba(0, 0, 0, 20%) 5%, rgba(0, 0, 0, 92%) 92%);
  }
`

const Background = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  > * {
    width: 100%;
    height: 100%;
  }
`

const Inner = styled.div`
  ${props => props.theme.gridContainer()}

  margin-top: ${rem(70)};

  @media ${props => props.theme.mediumDown} {
    margin-top: ${rem(20)};
  }
`

const Posts = styled.ul`
  ${props => props.theme.gridGrid()}
  ${props => props.theme.gridGridGutterX(null, { nested: true })}
  ${props => props.theme.gridGridGutterY()}

  margin-bottom: ${rem(50)};

  > li {
    ${props => props.theme.gridCell(3)}

    display: flex;

    @media ${props => props.theme.xlargeDown} {
      ${props => props.theme.gridCell(4)}
    }

    @media ${props => props.theme.mediumDown} {
      ${props => props.theme.gridCell(6)}
    }

    @media ${props => props.theme.xsmallDown} {
      ${props => props.theme.gridCell(12)}
    }
  }
`

const filterPostsByCategory = (posts, categories) => {
  const postsInCategory = posts.filter(({ node }) => {
    const postCategories = node.categories.map(category => category.wordpressId)
    const filterCategories = categories.map(category => category.termId)
    if (
      postCategories.some(categoryId => filterCategories.includes(categoryId))
    ) {
      return node
    }

    return null
  })

  return postsInCategory
}

const filterPostsByFeatured = posts => {
  const featuredPosts = posts.filter(({ node }) => node.acf.featured)
  return featuredPosts
}

const filterPosts = (posts, isFeatured, isCategory) => {
  if (isFeatured && isCategory && isCategory.length > 0) {
    const postsInCategory = filterPostsByCategory(posts, isCategory)
    const featuredPosts = filterPostsByFeatured(postsInCategory)
    return featuredPosts
  }

  if (isFeatured) {
    const featuredPosts = filterPostsByFeatured(posts)
    return featuredPosts
  }

  if (isCategory && isCategory.length > 0) {
    const postsInCategory = filterPostsByCategory(posts, isCategory)
    return postsInCategory
  }

  return posts
}

const sortPosts = posts => {
  const sortBy = `date`
  const sortedPosts = [...posts].sort((a, b) => {
    if (a.node[sortBy] > b.node[sortBy]) {
      return -1
    }
    if (a.node[sortBy] < b.node[sortBy]) {
      return 1
    }
    return 0
  })

  return sortedPosts
}

const filterAndSortPosts = (posts, isFeatured, isCategory) => {
  return sortPosts(filterPosts(posts, isFeatured, isCategory))
}

const limitPosts = (posts, limit) => {
  if (limit === `all`) return posts
  return posts.slice(0, parseInt(limit))
}

const PostListing = ({
  data: {
    published,
    htmlId,
    sectionHeading,
    limit,
    filterFeatured,
    filterCategory,
    cta,
    backgroundImage,
  },
  allPosts: { edges: allPosts },
}) => {
  if (!published) {
    return null
  }

  const filteredAndSortedPosts = filterAndSortPosts(
    allPosts,
    filterFeatured,
    filterCategory
  )

  const limitedPosts = limitPosts(filteredAndSortedPosts, limit)

  return (
    <Container
      id={htmlId}
      className={backgroundImage && backgroundImage.localFile && `has-bg`}
    >
      <SectionHeader title={sectionHeading} headingLevel="2" />

      <Inner>
        {limitedPosts && limitedPosts.length > 0 && (
          <Posts>
            {limitedPosts.map(({ node: post }, index) => (
              <li key={`${post.id}${index}`}>
                <PostBox {...post} />
              </li>
            ))}
          </Posts>
        )}

        <CallToActions cta={cta} layout="horizontal" textAlign="center" />
      </Inner>

      <Background>
        {backgroundImage && backgroundImage.localFile && (
          <Img
            fluid={backgroundImage.localFile.childImageSharp.fluid}
            alt={sectionHeading}
            role="presentation"
          />
        )}
      </Background>
    </Container>
  )
}

PostListing.propTypes = {
  data: PropTypes.object.isRequired,
  allPosts: PropTypes.object.isRequired,
}

export default PostListing
