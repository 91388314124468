import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import SectionHeader from "./section-header"

const Container = styled.section`
  background: ${props => props.theme.colorGreyDarkest};

  @media ${props => props.theme.mediumDown} {
    display: none;
  }
`

const WishListContainer = styled.div`
  ${props => props.theme.gridContainer()}

  padding: ${rem(40)} 0;
  max-width: ${rem(1920)};

  @media ${props => props.theme.mediumDown} {
    padding: ${rem(10)} 0;
    max-width: calc(100% - ${rem(20)});
  }
`

const WishListWidget = styled.article`
  margin-bottom: ${rem(10)};
  position: relative;
  text-align: center;

  @media ${props => props.theme.mediumDown} {
    display: flex;
    flex-direction: column-reverse;
  }
`

const SteamWishList = ({ data }) => {
  if (!data.published) return null

  return (
    <Container id={data.htmlId}>
      <SectionHeader title={data.sectionHeading} headingLevel="2" />

      <WishListContainer>
        <WishListWidget>
          <iframe
            src={`https://store.steampowered.com/widget/${data.gameId}`}
            frameBorder="0"
            width="646"
            height="190"
            title="Steam Wishlist Widget"
          ></iframe>
        </WishListWidget>
      </WishListContainer>
    </Container>
  )
}

SteamWishList.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SteamWishList
