import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import Img from "gatsby-image/withIEPolyfill"

import SectionHeader from "./section-header"
import CallToActions from "./call-to-actions"

const Container = styled.section`
  background: ${props => props.theme.colorGreyDarkest};
`

const Features = styled.div`
  ${props => props.theme.gridContainer()}

  padding: ${rem(40)} 0;
  max-width: ${rem(1920)};

  @media ${props => props.theme.mediumDown} {
    padding: ${rem(10)} 0 0;
    max-width: 100%;
  }

  @media ${props => props.theme.smallDown} {
    max-width: 100%;
  }
`

const FeatureText = styled.div`
  ${props => props.theme.gridContainer()}
  ${props => props.theme.gridGrid()}

  max-width: 100%;
  position: relative;
  z-index: 2;

  @media ${props => props.theme.mediumDown} {
    justify-content: center;
  }

  > div {
    ${props => props.theme.gridCell(5)}

    margin-left: ${rem(30)};

    @media ${props => props.theme.mediumDown} {
      ${props => props.theme.gridCell(12)}

      margin-left: 0;
      padding: ${rem(20)} 0;
    }

    > div {
      max-width: ${rem(480)};
      margin: 0 auto;
      padding: ${rem(80)} 0;

      @media ${props => props.theme.mediumDown} {
        padding: ${rem(10)} 0;
      }
    }
  }
`

const FeatureHeading = styled.div`
  margin-bottom: ${rem(10)};

  span {
    letter-spacing: rem(2);
    font-weight: 400;
    margin-bottom: ${rem(10)};
    display: block;
    color: ${props => props.theme.colorYellow};
  }

  b {
  }
`

const FeatureDescription = styled.div`
  > * + * {
    margin-top: ${rem(10)};
  }
`

const FeatureImage = styled.div`
  width: 55%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  clip-path: polygon(${rem(40)} 0, 100% 0, 100% 100%, 0 100%);

  @media ${props => props.theme.mediumDown} {
    z-index: 0;
    width: 100%;
    position: static;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - ${rem(10)}), 0 100%);
  }

  > * {
    width: 100%;
    height: 100%;
  }
`

const Feature = styled.article`
  margin-bottom: ${rem(10)};
  position: relative;
  text-align: center;
  background-image: linear-gradient(180deg, #1b1b19 0%, #0a0a0a 100%);

  @media ${props => props.theme.mediumDown} {
    margin-bottom: 0;
    display: flex;
    flex-direction: column-reverse;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
  }

  &::before {
    width: 100%;
    background-size: 6px 6px;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNCAxNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTQgMTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMjYyNjI2O30KPC9zdHlsZT4KPGc+Cgk8Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSIyLjUiIGN5PSIyLjUiIHI9IjIuNSIvPgo8L2c+CjxnPgoJPGNpcmNsZSBjbGFzcz0ic3QwIiBjeD0iOS41IiBjeT0iOS41IiByPSIyLjUiLz4KPC9nPgo8L3N2Zz4K");
  }

  &::after {
    width: 50%;
    background-image: radial-gradient(
      50% 100%,
      rgba(27, 27, 25, 0) 47%,
      rgba(0, 0, 0, 0.5) 100%
    );

    @media ${props => props.theme.mediumDown} {
      width: 100%;
    }
  }

  &:nth-child(odd)::after {
    left: 0;
  }

  &:nth-child(even)::after {
    right: 0;
  }

  &:nth-child(even) {
    ${FeatureImage} {
      right: auto;
      left: 0;
      clip-path: polygon(0 0, calc(100% - ${rem(40)}) 0, 100% 100%, 0 100%);

      @media ${props => props.theme.mediumDown} {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - ${rem(10)}));
      }
    }

    ${FeatureText} {
      > div {
        margin-left: auto;
        margin-right: ${rem(20)};

        @media ${props => props.theme.mediumDown} {
          margin-right: auto;
        }
      }
    }
  }
`

const FeatureList = ({ data }) => {
  if (!data.published) return null

  return (
    <Container id={data.htmlId}>
      <SectionHeader title={data.sectionHeading} headingLevel="2" />

      {data.features && data.features.length > 0 && (
        <Features>
          {data.features.map((feature, i) => (
            <Feature key={i}>
              <FeatureText>
                <div>
                  <div>
                    <FeatureHeading as={data.sectionHeading ? `h3` : `h2`}>
                      {feature.label && (
                        <span
                          dangerouslySetInnerHTML={{ __html: feature.label }}
                          className="styled-h3"
                        />
                      )}

                      {feature.title && (
                        <b
                          dangerouslySetInnerHTML={{ __html: feature.title }}
                          className="styled-h2"
                        />
                      )}
                    </FeatureHeading>

                    {feature.description && (
                      <FeatureDescription
                        dangerouslySetInnerHTML={{
                          __html: feature.description,
                        }}
                      />
                    )}

                    <CallToActions
                      cta={feature.cta}
                      layout="horizontal"
                      textAlign="center"
                    />
                  </div>
                </div>
              </FeatureText>

              {feature.image && feature.image.localFile && (
                <FeatureImage>
                  <Img
                    fluid={feature.image.localFile.childImageSharp.fluid}
                    alt={`${feature.label} ${feature.title}`}
                  />
                </FeatureImage>
              )}
            </Feature>
          ))}
        </Features>
      )}
    </Container>
  )
}

FeatureList.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FeatureList
