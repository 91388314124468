import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"
import getYouTubeID from "get-youtube-id"
import Img from "gatsby-image/withIEPolyfill"

import PlayButton from "../play-button"
import Link from "../link"

const ThumbnailImage = styled.figure`
  &,
  a,
  a > * {
    width: 100%;
    height: 100%;
    display: block;
  }

  > a {
    overflow: hidden;

    &:hover > * {
      transform: scale(1.05);
    }

    &:active > * {
      opacity: 0.8;
    }

    > * {
      transition: 0.2s ${props => props.theme.easingDefault};
      transition-property: transform, opacity;
      will-change: transform;
    }
  }
`

const ThumbnailVideo = styled.figure`
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &:hover > img {
    transform: scale(1.05);

    ${props =>
      props.large === true &&
      css`
        @media ${props => props.theme.xsmallUp} {
          transform: scale(1.01);
        }
      `}
  }

  &:active > img {
    opacity: 0.8;
  }

  &,
  > img {
    width: 100%;
    height: 100%;
    display: block;
  }

  > img {
    object-fit: cover;
    transition: 0.2s ${props => props.theme.easingDefault};
    transition-property: transform, opacity;
    will-change: transform;
  }

  a {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${props =>
      props.large === true &&
      css`
        @media ${props => props.theme.xlargeDown} {
          font-size: ${rem(12)};
        }

        @media ${props => props.theme.xsmallDown} {
          font-size: ${rem(8)};
        }
      `}

    ${props =>
      props.large !== true &&
      css`
        font-size: ${rem(8)};
      `}
  }
`

const Thumbnail = ({ media, large, onClick }) => {
  switch (media.type) {
    case `image`: {
      if (!media.image || !media.image.localFile) return null
      return (
        <ThumbnailImage large={large}>
          <Link
            to={media.image.localFile.childImageSharp.fluid.src}
            onClick={onClick}
            role="button"
            title="Open large image"
          >
            <Img
              fluid={media.image.localFile.childImageSharp.fluid}
              alt={media.description || ``}
            />
          </Link>
        </ThumbnailImage>
      )
    }

    case `video`: {
      if (!media.videoUrl) return null
      const videoId = getYouTubeID(media.videoUrl)
      if (!videoId) return null
      return (
        <ThumbnailVideo large={large} onClick={onClick}>
          <PlayButton to="/" onClick={onClick} />

          <img
            src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
            alt={media.description}
          />
        </ThumbnailVideo>
      )
      // TODO: ^ implement `srcset`: https://stackoverflow.com/a/20542029
    }

    default:
      return null
  }
}

Thumbnail.propTypes = {
  media: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  large: PropTypes.bool,
}

export default Thumbnail
