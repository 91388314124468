import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import Img from "gatsby-image/withIEPolyfill"

import Link from "./link"

const Image = styled.figure`
  width: 100%;
  height: ${rem(190)};
  clip-path: polygon(100% 0, 100% 100%, 0 96%, 0 0);
  overflow: hidden;

  > * {
    width: 100%;
    height: 100%;
    transition: 0.2s ${props => props.theme.easingDefault};
    transition-property: transform, opacity;
    will-change: transform;
  }
`

const Inner = styled.div`
  padding: ${rem(25)} ${rem(30)};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const Title = styled.h3.attrs({ className: `styled-p` })`
  margin-bottom: ${rem(50)};
  line-height: 1.4;
`

const Date = styled.time`
  ${props => props.theme.ffSecondarySet()}

  color: ${props => props.theme.colorYellow};
  margin-top: auto;
  font-size: ${rem(16)};
  line-height: 1;
  letter-spacing: 0.05em;
`

const Container = styled.article`
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  background-color: ${props => props.theme.colorBlack};

  background-size: 6px 6px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNCAxNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTQgMTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMjYyNjI2O30KPC9zdHlsZT4KPGc+Cgk8Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSIyLjUiIGN5PSIyLjUiIHI9IjIuNSIvPgo8L2c+CjxnPgoJPGNpcmNsZSBjbGFzcz0ic3QwIiBjeD0iOS41IiBjeT0iOS41IiByPSIyLjUiLz4KPC9nPgo8L3N2Zz4K");
  box-shadow: 0 ${rem(3)} 0 0 rgba(0, 0, 0, 0.3);

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
      100% 110%,
      rgba(10, 10, 10, 0.2) 20%,
      rgba(10, 10, 10, 0.9) 100%
    );
  }
`

const ContainerLink = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: column;
  transform: scale(1.01);

  &:hover ${Image} > * {
    transform: scale(1.05);
  }

  &:active ${Image} > * {
    opacity: 0.8;
  }
  background: radial-gradient(
    transparent 45%,
    ${props => props.theme.colorBlack} 100%
  );
`

const Post = ({ slug, title, date, acf: { image } }) => {
  return (
    <Container>
      <ContainerLink to={`/news/${slug}`}>
        {image && image.localFile && (
          <Image>
            <Img fluid={image.localFile.childImageSharp.fluid} alt={title} />
          </Image>
        )}

        <Inner>
          <Title dangerouslySetInnerHTML={{ __html: title }} />

          <Date datetime={date}>{date}</Date>
        </Inner>
      </ContainerLink>
    </Container>
  )
}

Post.propTypes = {
  slug: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  acf: PropTypes.object,
}

export default Post
