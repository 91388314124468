import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"
import Img from "gatsby-image/withIEPolyfill"

import SectionHeader from "../section-header"
import CallToActions from "../call-to-actions"
import Thumbnail from "./thumbnail"
import MediaModalCarousel from "../media-modal-carousel"

const Container = styled.section`
  background: radial-gradient(rgba(0, 0, 0, 20%) 5%, rgba(0, 0, 0, 92%) 92%);
  padding-top: ${rem(60)};
  padding-bottom: ${rem(110)};
  position: relative;

  ${props =>
    props.hasSectionHeading &&
    css`
      padding-top: 0;
    `}

  @media ${props => props.theme.mediumDown} {
    padding-bottom: ${rem(60)};
  }
`

const SectionHeaderStyled = styled(SectionHeader)`
  margin-bottom: ${rem(70)};

  @media ${props => props.theme.mediumDown} {
    margin-bottom: ${rem(20)};
  }
`

const BackgroundImage = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  > * {
    width: 100%;
    height: 100%;
  }
`

const Featured = styled.div`
  ${props => props.theme.gridContainer()}

  margin-bottom: ${rem(20)};
`

const List = styled.ul`
  ${props => props.theme.gridContainer()}
  ${props => props.theme.gridGrid()}
  ${props => props.theme.gridGridGutterX()}
  ${props => props.theme.gridGridGutterY()}

  > li {
    ${props =>
      props.columns &&
      css`
        ${props => props.theme.gridCell(12 / props.columns)}
      `}

    ${props =>
      props.columns > 3 &&
      css`
        @media ${props => props.theme.xlargeDown} {
          ${props =>
            props.theme.gridCell(
              Math.min(12 / (props.columns - 1), 4) // min 3 cols
            )}
        }
      `}

    ${props =>
      props.columns > 2 &&
      css`
        @media ${props => props.theme.mediumDown} {
          ${props =>
            props.theme.gridCell(
              Math.min(12 / (props.columns - 2), 6) // min 2 cols
            )}
        }
      `}

    @media ${props => props.theme.xsmallDown} {
      ${props => props.theme.gridCell(12)}
    }
  }
`

const Masonry = styled.ul`
  ${props => props.theme.gridContainer()}

  column-count: 4;
  column-gap: 0;
  line-height: 0;
  display: block;

  ${props =>
    props.columns &&
    css`
      column-count: ${props => props.columns};
    `}

  ${props =>
    props.columns > 3 &&
    css`
      @media ${props => props.theme.xlargeDown} {
        column-count: ${props => Math.min(props.columns - 1, 3)}; // min 3 cols
      }
    `}

  ${props =>
    props.columns > 2 &&
    css`
      @media ${props => props.theme.mediumDown} {
        column-count: ${props => Math.min(props.columns - 2, 2)}; // min 2 cols
      }
    `}

  @media ${props => props.theme.xsmallDown} {
    column-count: 1;
  }

  > li {
    width: 100%;
    display: inline-block;
  }
`

const MediaGallery = ({ data }) => {
  const [activeMedia, setActiveMedia] = useState(null)

  if (!data.published) return null

  const content = data.content.filter(c => {
    if (c.type == `image`) return c.image && c.image.localFile
    if (c.type == `video`) return !!c.videoUrl
    return false
  })

  let featuredContent = null
  let ordinaryContent = content
  if (data.featured && content.length) {
    featuredContent = content[0]
    ordinaryContent = content.slice(1)
  }

  const thumbnailClick = (e, index) => {
    e.preventDefault()
    setActiveMedia(index)
  }

  return (
    <Container id={data.htmlId} hasSectionHeading={!!data.sectionHeading}>
      <SectionHeaderStyled title={data.sectionHeading} headingLevel="2" />

      {data.content.length > 0 && (
        <React.Fragment>
          {featuredContent && (
            <Featured>
              <Thumbnail
                media={featuredContent}
                large={true}
                onClick={e => thumbnailClick(e, 0)}
              />
            </Featured>
          )}

          {ordinaryContent.length > 0 && (
            <React.Fragment>
              {data.style == `grid-16x9` && (
                <List columns={data.columns || 3}>
                  {ordinaryContent.map((media, i) => (
                    <li key={i}>
                      <Thumbnail
                        media={media}
                        onClick={e =>
                          thumbnailClick(e, i + (featuredContent ? 1 : 0))
                        }
                      />
                    </li>
                  ))}
                </List>
              )}

              {data.style == `masonry` && (
                <Masonry columns={data.columns || 3}>
                  {ordinaryContent.map((media, i) => (
                    <li key={i}>
                      <Thumbnail
                        media={media}
                        onClick={e =>
                          thumbnailClick(e, i + (featuredContent ? 1 : 0))
                        }
                      />
                    </li>
                  ))}
                </Masonry>
              )}
            </React.Fragment>
          )}

          <CallToActions
            cta={data.cta}
            layout="horizontal"
            textAlign="center"
          />
        </React.Fragment>
      )}

      {data.backgroundImage && data.backgroundImage.localFile && (
        <BackgroundImage>
          <Img
            fluid={data.backgroundImage.localFile.childImageSharp.fluid}
            alt={data.sectionHeading || ``}
            role="presentation"
          />
        </BackgroundImage>
      )}

      {activeMedia !== null && (
        <MediaModalCarousel
          data={content}
          currentSlide={activeMedia}
          onRequestClose={() => setActiveMedia(null)}
        />
      )}
    </Container>
  )
}

MediaGallery.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MediaGallery
