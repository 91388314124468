import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"

import CallToActions from "./call-to-actions"

const Container = styled.div`
  text-align: ${props => (props.textAlign === `center` ? `center` : `left`)};
`
const Inner = styled.div`
  ${props => props.theme.gridContainer()}

  padding-top: ${rem(70)};
  padding-bottom: ${rem(70)};
`

const Wrap = styled.div`
  ${props => props.theme.gridCell(10)}

  @media ${props => props.theme.largeUp} {
    ${props => props.theme.gridCell(8)}
  }

  @media ${props => props.theme.xlargeUp} {
    ${props => props.theme.gridCell(6)}
  }

  ${props =>
    props.textAlign === `center`
      ? css`
          margin-left: auto;
          margin-right: auto;
        `
      : ``};
`

const Title = styled.h2.attrs({ className: `styled-h1` })`
  word-break: break-word;
  margin-bottom: ${rem(20)};

  em {
    color: ${props => props.theme.colorYellow};
    font-style: normal;
  }
`

const Description = styled.div.attrs({ className: `styled-p-large` })``

const Heading = ({
  data: { published, htmlId, title, description, cta, textAlign },
}) => {
  if (!published) return null

  return (
    <Container id={htmlId} textAlign={textAlign}>
      <Inner>
        <Wrap textAlign={textAlign}>
          <Title dangerouslySetInnerHTML={{ __html: title }} />

          <Description dangerouslySetInnerHTML={{ __html: description }} />

          <CallToActions cta={cta} layout="horizontal" textAlign={textAlign} />
        </Wrap>
      </Inner>
    </Container>
  )
}

Heading.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Heading
