import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import CtaBlurbs from "./cta-blurbs"
import BigBlurb from "./big-blurb"
import FeatureList from "./feature-list"
import FeatureGallery from "./feature-gallery"
import HeroIntroduction from "./hero-introduction"
import HeroCta from "./hero-cta"
import CharacterList from "./character-list"
import PostListing from "./post-listing"
import MediaGallery from "./media-gallery"
import Heading from "./heading"
import SteamWishList from "./steam-wishlist"

const headerContainingComponents = [
  `WordPressAcf_hero_text`,
  `WordPressAcf_hero_video`,
]

const Components = ({ components, allPosts, allCharacters }) => {
  if (!components || components.length <= 0) {
    return <Header />
  }

  return (
    <React.Fragment>
      {!headerContainingComponents.includes(components[0].__typename) && (
        <Header />
      )}

      {components.map((child, index) => {
        if (child.__typename === `WordPressAcf_hero_text`) {
          return (
            <HeroIntroduction
              key={child.id}
              data={child}
              displayHeader={index === 0}
            />
          )
        }
        if (child.__typename === `WordPressAcf_hero_video`) {
          return (
            <HeroCta key={child.id} data={child} displayHeader={index === 0} />
          )
        }
        if (child.__typename === `WordPressAcf_cta_blurbs`) {
          return <CtaBlurbs key={child.id} data={child} />
        }
        if (child.__typename === `WordPressAcf_big_blurb`) {
          return <BigBlurb key={child.id} data={child} />
        }
        if (child.__typename === `WordPressAcf_feature_list`) {
          return <FeatureList key={child.id} data={child} />
        }
        if (child.__typename === `WordPressAcf_feature_gallery`) {
          return <FeatureGallery key={child.id} data={child} />
        }
        if (child.__typename === `WordPressAcf_character_list`) {
          return (
            <CharacterList
              key={child.id}
              data={child}
              allCharacters={allCharacters}
            />
          )
        }
        if (child.__typename === `WordPressAcf_post_listing`) {
          return <PostListing key={child.id} data={child} allPosts={allPosts} />
        }
        if (child.__typename === `WordPressAcf_media_gallery`) {
          return <MediaGallery key={child.id} data={child} />
        }
        if (child.__typename === `WordPressAcf_heading`) {
          return <Heading key={child.id} data={child} />
        }
        if (child.__typename === `WordPressAcf_steam_wishlist`) {
          return <SteamWishList key={child.id} data={child} />
        }

        return null
      })}
    </React.Fragment>
  )
}
Components.propTypes = {
  components: PropTypes.array,
  allPages: PropTypes.object,
  allPosts: PropTypes.object,
  allCharacters: PropTypes.object,
}

export default Components
