import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { rem } from "polished"

import Link from "../link"

const Image = styled.figure`
  margin: 0 -2.2%;
  transition: 0.2s ${props => props.theme.easingDefault};
  transition-property: transform, opacity;
  will-change: transform;

  @media ${props => props.theme.xsmallDown} {
    margin: 0;
  }

  > * {
    clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
  }
`

const Title = styled.h3.attrs({ className: `styled-h3` })`
  margin-top: ${rem(20)};
  text-align: center;
  color: ${props => props.theme.colorYellow};
`

const Container = styled.article`
  width: 100%;

  > a {
    display: block;

    &:hover {
      position: relative;
      z-index: 1;
    }

    &:hover ${Image} {
      transform: scale(1.025);
      filter: drop-shadow(0 0 ${rem(10)} rgba(0, 0, 0, 1));
    }

    &:active ${Image} {
      opacity: 0.8;
    }
  }
`

const Feature = ({ data: { title, thumbnail, image }, onClick }) => {
  return (
    <Container>
      <Link
        to={image.localFile.childImageSharp.fluid.src}
        onClick={onClick}
        target="_blank"
      >
        <Image>
          <Img
            fluid={{
              ...thumbnail.localFile.childImageSharp.fluid,
              aspectRatio: 1 / 1,
            }}
            alt={title}
          />
        </Image>

        <Title>{title}</Title>
      </Link>
    </Container>
  )
}

Feature.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

export default Feature
