import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import Img from "gatsby-image/withIEPolyfill"

import SectionHeader from "../section-header"
import MediaModalCarousel from "../media-modal-carousel"
import Feature from "./feature"

const Container = styled.section`
  position: relative;
  background: radial-gradient(rgba(0, 0, 0, 20%) 5%, rgba(0, 0, 0, 92%) 92%);
`

const Features = styled.ul`
  ${props => props.theme.gridContainer()}
  ${props => props.theme.gridGrid()}
  ${props => props.theme.gridGridGutterY(40)}

  padding-top: ${rem(60)};
  padding-bottom: ${rem(80)};

  @media ${props => props.theme.xxxxlargeDown} {
    padding-left: ${rem(40)};
    padding-right: ${rem(40)};
  }

  @media ${props => props.theme.mediumDown} {
    padding-top: ${rem(20)};
    padding-bottom: ${rem(50)};
  }

  > li {
    ${props => props.theme.gridCell(3)}

    @media ${props => props.theme.largeDown} {
      ${props => props.theme.gridCell(4)}
    }

    @media ${props => props.theme.smallDown} {
      ${props => props.theme.gridCell(6)}
    }

    @media ${props => props.theme.xsmallDown} {
      ${props => props.theme.gridCell(12)}
    }
  }
`

const Background = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  > * {
    width: 100%;
    height: 100%;
  }
`

const featuresForCarouselAdapter = features => {
  const data = []
  features.forEach(feature => {
    data.push({
      type: `image`,
      image: feature.image,
      description: `${feature.description}`,
    })
  })
  return data
}

const FeatureGallery = ({
  data: { published, htmlId, sectionHeading, features, backgroundImage },
}) => {
  if (!published) {
    return null
  }

  const [activeFeature, setActiveFeature] = useState(null)

  const carouselData = featuresForCarouselAdapter(features)

  const featureClick = (e, index) => {
    e.preventDefault()
    setActiveFeature(index)
  }

  return (
    <Container id={htmlId}>
      <SectionHeader title={sectionHeading} headingLevel="2" />

      {features && features.length > 0 && (
        <Features>
          {features.map((feature, i) => (
            <li key={i}>
              <Feature data={feature} onClick={e => featureClick(e, i)} />
            </li>
          ))}
        </Features>
      )}

      {activeFeature !== null && (
        <MediaModalCarousel
          data={carouselData}
          currentSlide={activeFeature}
          onRequestClose={() => setActiveFeature(null)}
        />
      )}

      <Background>
        {backgroundImage && backgroundImage.localFile && (
          <Img
            fluid={backgroundImage.localFile.childImageSharp.fluid}
            alt={sectionHeading}
            role="presentation"
          />
        )}
      </Background>
    </Container>
  )
}

FeatureGallery.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FeatureGallery
